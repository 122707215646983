import { Clock } from "../Clock/Clock";
import { Typography } from "@mui/material";
import { generateQrCode } from "../../../../Utilities/Various";
import { useEffect, useState } from "react";
import React from "react";

export function MainInformation() {
  const [qrCode, setQrCode] = useState("");
  // const formUrl = process.env.REACT_APP_FormSansRdvUrl ?? 'https://forms.office.com/e/uzHCvLhWZG';

  // useEffect(() => {
  //   generateQrCode(
  //     "000000",
  //     "FFFFFF",
  //     process.env.REACT_APP_FrontEndPoint + "/tallymaker"
  //   ).then((response) => setQrCode(response));
  // }, []);

  const formUrl = React.useMemo(() => {
    const envUrl = process.env.REACT_APP_FormSansRdvUrl;
    if (!envUrl) {
      console.error("ERREUR: REACT_APP_FormSansRdvUrl n'est pas défini dans le .env");
      throw new Error("URL du formulaire non définie");
    }
    return envUrl;
  }, []);

  useEffect(() => {
    generateQrCode(
      "000000",
      "FFFFFF",
      formUrl
    ).then((response) => setQrCode(response));
  }, [formUrl]);
  
  

  return (
    <div
    style={{
      display: "flex",
      flexGrow: "1",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <img
      style={{ width: "400px", marginTop: "-30px" }} 
      src="./logoAltyor.png"
      alt="logo altyor"
    />

<Typography 
      variant="h2" 
      component="div" 
      style={{ 
        textAlign: "center",
        marginTop: "20px"
      }}
    >
      Bienvenue dans nos locaux !
    </Typography>
    
    <Typography 
      variant="h5" 
      component="div" 
      style={{ 
        textAlign: "center",
        marginTop: "10px"
      }}
    >
      N'oubliez pas de valider votre présence en scannant le QR code :
    </Typography>
    
    <div style={{ 
      display: "flex", 
      flexDirection: "column", 
      alignItems: "center",
      margin: "10px 0"
    }}>
      <Typography variant="h6" component="div">• À votre arrivée</Typography>
      <Typography variant="h6" component="div">• À votre départ</Typography>
    </div>
    
  
    <img
      style={{ width: "150px", height: "150px", padding: "15px" }}
      src={qrCode}
      alt="Emargez"
    />
    <Typography variant="h4" component="div">
      Vous n'avez pas rendez-vous ?
    </Typography>
    <Typography variant="h5" component="div">
      Appelez le standard ci-dessous
    </Typography>
    <Typography variant="body1" component="div">
      NodOn : 09.72.37.78.75
    </Typography>
    <Typography variant="body1" component="div">
      Tiloli : 02.38.63.41.71
    </Typography>
    <Typography variant="body1" component="div">
      Altyor : 02.38.25.00.90
    </Typography>
    <Typography variant="body1" component="div">
      Altyor RH : 09.72.54.45.88
    </Typography>
    

    <div style={{ 
    width: '100%',
    display: 'flex',
    marginTop: '5vh',
    justifyContent: 'center',

  }}>
    <Clock></Clock>
    </div>
  </div>
  
  );
}
